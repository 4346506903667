.accuracy_button {
  display: none;
}

@keyframes standbyColorAnimation {
  0% {
    background-color: var(--color-primary);
  }
  50% {
    background-color: red;
  }
  100% {
    background-color: var(--color-primary);
  }
}

.accuracy_button.standby {
  display: block;
  position: absolute;
  padding: 10px;
  border-radius: 10px;
  margin: 0 auto 50px auto;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  opacity: 0.85;
  animation: standbyColorAnimation 3s infinite;
}
