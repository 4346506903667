.language-flag {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  border: 2px solid transparent;
  transition: border-color 0.2s;
  min-width: 40px;
  color: black;
}

.language-flag:hover,
.language-flag.active {
  border-color: var(--color-primary);
}

.language-flag.disabled,
.language-flag.disabled:hover {
  border-color: #f3f3f3;
  background: #f3f3f3;
}

.language-flag>*:not(:last-child) {
  margin-bottom: 4px;
}

.language-flag img {
  max-width: 100%;
  object-fit: contain;
  border: 1px solid lightgray;
}

.language-flag .active-icon {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  color: var(--color-primary);
  font-size: 1.6rem;
  background-color: white;
  height: 1.6rem;
  line-height: 1.6rem;
  border-radius: 2rem;
}

.flag-button {
  display: flex;
  padding: 4px 10px;
  background-color: #F7F7F7;
  border-radius: 10em;
  box-shadow: 8px 5px 10px -5px rgb(172, 172, 172);
}
