.App {
  background: linear-gradient(45deg, var(--color-gray-bg) 0%, var(--color-gray-bg-2) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.App.connecting {
  display: none;
}


.logo-bg {
  pointer-events: none;
  position: fixed;
  bottom: 0;
  left: 0;
  opacity: 0.25;
  transform: translate(-10%, 30%);
}

.logo-bg>img {
  width: calc(200px + 15vw);
  height: calc(200px + 15vw);
  max-width: 512px;
  max-height: 512px;
}

.logo-wide {
  width: 100%;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .logo-wide {
    width: 60vw;
    max-width: 600px;
  }
}
