.not-found-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.not-found-page .grad-02 {
  --grad-deg: 90deg;
}

.not-found-page .logo-bg {
  bottom: 50%;
  left: unset;
  right: 0;
  transform: translate(18%, 50%);
}

.not-found-page .logo-bg>img {
  width: calc(200px + 25vw);
  height: calc(200px + 25vw);
  max-width: 1024px;
  max-height: 1024px;
}
