.SummaryOnly .logo-bg,
.SummaryOnly .main-page div > .btn.btn-primary.btn-sm,
.SummaryOnly .chat-header,
.SummaryOnly .box-left,
.SummaryOnly .chat-list-container,
.SummaryOnly .bottom-bar,
.SummaryOnly #userInfo{
  display: none !important;
}

.SummaryOnly .main-page{
  padding: 0 !important;
  margin: 0 !important;
}

.SummaryOnly .box-summary {
  width: 100%;
  max-width: 100%;
  max-height: calc(100dvh - 52px);
  display: block !important;
}
.SummaryOnly .box-summary > h4 svg {
  display: none !important;
}
