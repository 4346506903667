body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color-primary: #01A0E9;
  --color-primary-light: #42C8F4;
  --color-primary-dark: #1075BB;
  --color-gray: #E6E6E8;
  --color-gray-bg: #F2F0F0;
  --color-gray-bg-2: #FDFDFD;
  --color-text: #7F7F7F;

  color: var(--color-text);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.one-line {
  white-space: nowrap;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-primary {
  color: var(--color-primary) !important;
}

.form-check-input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.btn-primary {
  color: #fff;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.box-01 {
  box-shadow: 5px 5px 15px -5px rgb(172, 172, 172);
  border-radius: .4rem;
}

.grad-01 {
  --grad-deg: 225deg;
  background-image: linear-gradient(var(--grad-deg),
      hsl(21deg 20% 84%) 8%,
      hsl(204deg 16% 82%) 39%,
      hsl(201deg 45% 79%) 49%,
      hsl(200deg 68% 75%) 57%,
      hsl(198deg 85% 71%) 67%,
      hsl(196deg 98% 63%) 93%);
}

.grad-02 {
  --grad-deg: 225deg;
  background-image: linear-gradient(var(--grad-deg),
      hsl(231deg 100% 66%) 8%,
      hsl(222deg 100% 66%) 39%,
      hsl(214deg 100% 65%) 49%,
      hsl(208deg 100% 64%) 57%,
      hsl(202deg 100% 63%) 67%,
      hsl(197deg 100% 63%) 93%);
}

.grad-03 {
  --grad-deg: 225deg;
  background-image: linear-gradient(var(--grad-deg),
      hsl(0deg 100% 35%) 8%,
      hsl(0deg 75% 44%) 39%,
      hsl(0deg 68% 50%) 49%,
      hsl(0deg 77% 54%) 57%,
      hsl(0deg 87% 59%) 67%,
      hsl(0deg 100% 63%) 93%);
}