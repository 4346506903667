.record-container {
  position: relative;
}

.record-container .chat-interim {
  max-width: 600px;
  border-radius: 10px;
  font-size: 1.5rem;
  max-height: 25vh;
  overflow-y: auto;
}

.mic-btn {
  border: none;
  border-radius: 50%;
  width:  64px;
  height: 64px;
  background-image: none;
  background-color: gray;
}

.mic-btn:not(:disabled):hover {
  opacity: 0.9;
}

.mic-btn:disabled {
  opacity: 0.5;
}

.mic-btn.active {
  background-color: red;
}

.mic-btn .mic-icon {
  font-size: 2rem;
  color:     white;
}

.mic-btn.grad-02 {
  --grad-deg: 270deg;
}

.mic-btn.grad-03 {
  --grad-deg: 270deg;
}

.control-panel {
  border-radius: 6rem;
  border: 2px solid lightgray;
  padding: 3px;
  gap: 4vw;
}
