#userInfo {
  color: var(--color-primary);
}

.language-select .language-flag {
  width: 100%;
}

.language-select .language-flag img {
  width: 100%;
}

.selected-language {
  width: 120px;
}