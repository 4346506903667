.chat-row {
  padding-left: 20%;
  padding-bottom: 10px;
}
.chat-row-draft {
  opacity: 0;
  animation: fadeIn 1s forwards;
  padding-bottom: 10px;
}
.chat-row-draft .text-black {
  padding-bottom: 28px;
}
.chat-container .chat-row-draft .text-black {
  padding-bottom: 0;
}

.chat-row:last-child {
  padding-bottom: 20px;
}

.chat-row>*>*:not(:last-child) {
  margin-right: 5px;
}

.chat-row .chat-text-container {
  flex: 1;
}

.chat-row .chat-text {
  padding: 5px 10px;
  background-color: #C7C7C7;
  border-radius: 10px;
  border-top-right-radius: 0;
  font-size: 1.24em;
  letter-spacing: 0.05em;
  color: #666666;
}

.chat-row.other {
  padding-left: 0;
  padding-right: 20%;
}

.chat-row.other .chat-text {
  background-color: #D6D6D6;
  border-top-right-radius: 10px;
  border-top-left-radius: 0;
}

.chat-row .chat-text .original-text {
  font-size: 1.4rem;
  color: #000000 !important;
  font-weight: bold;
}

.chat-text-error {
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
  color: #FFFFFF !important;
}
.chat-alert-mark {
  padding: 2px 6px;
  font-size: 22px;
  vertical-align: -2px;
}
.chat-retry {
  cursor: pointer;
}
.chat-retry .chat-message-tooltip {
  font-size: 16px;
  padding: 6px 12px;
  border-radius: 16px;
  z-index: 10000;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important;
}

.loading-chat-row .chat-text {
  background-color: #e7e7e7;
  opacity: 0;
  animation: fadeIn 1s forwards;
}

.dot {
  opacity: 0;
  animation: fadeIn 0.5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.chat-row small {
  font-size: 1.2em;
  color: #666666;
  font-weight: bold;
}

.chat-row .flag-inline {
  vertical-align: inherit;
}
