.minutes {
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  opacity: 0;
  display: none;
}
.minutes.show {
  display: block;
  opacity: 1;
}
