.main-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.main-page .language-flag {
  padding: 0;
  width: 32px;
}

.chat-header {
  max-width: 650px;
}

.chat-header .logo-wrap {
  padding-bottom: 30px;
}
.chat-header .lang-wrap {
  padding-bottom: 22px;
}

.main-wrapper {
  height: calc(100dvh - 320px);
}

.main-container {
  position: relative;
  flex: 1 1 auto;
  max-width: 650px;
  padding: 0;
}

.main-container .accordion {
  background-color: white;
}

.main-container .collapsing {
  opacity: 0;
}

.main-container .accordion .accordion-body {
  padding: 0;
}

.main-container .btn-chat-switch {
  color: #747474;
  background-color: #efefef;
  padding: 0;
  vertical-align: baseline;
}

.main-container .btn-chat-switch.show-chat {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-color: #e2e2e2;
}

.main-container .btn-chat-switch.show-advisor {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: #e2e2e2;
  background-color: #c9e0ff;
}

.chat-container, .advisor-container {
  height: calc(100dvh - 410px);
  overflow: auto;
}

.no-token {
  height: calc(100dvh - 375px);
  overflow: auto;
  background: #FFFFFF;
}

.no-token h1 {
  position: absolute;
  top: 50%;
  margin-top: -1em;
  display: block;
  width: 100%;
}

.advisor-container {
  background: #f9feff;
}

.advisor-container .chat-row:not(:last-child) {
  margin-bottom: 20px;
}

.advisor-container .user-avatar {
  cursor: default;
}

.advisor-container .chat-text p {
  margin-bottom: 0.5em;
  line-height: 1.5em;
}

.advisor-container .chat-text p:last-child {
  margin-bottom: 0;
}

.footer-input-form {
  position: relative;
  margin-top: 12px;
}

.footer-input-form-control {
  padding-right: 48px;
}

.footer-input-form-submit-button{
  position: absolute;
  top: 1px;
  right: 1px;
  line-height: 2.1em;
  padding: 0 15px;
  cursor: pointer;
  background: #f7f7f7;
  border-left: 1px solid #f3efef;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bottom-bar {
  flex: 0 0 content;
  text-align: center;
  max-width: 650px;
}

.gptech-logo {
  width:      230px;
  max-height: 75px;
}

.gptech-logo-phone {
  width:      200px;
  max-height: 75px;
  transform-origin: center left;
  scale: 80%;
}
@media (max-width: 500px) {
  .gptech-logo-phone {
    width:      130px;
  }
}

.box-right, .box-left{
  flex: 1 1 auto;
  position: relative;
  max-width: 400px;
  max-height: calc(100vh - 408px);
}
.summary-bubble {
  margin: 1px;
  border-radius: 10px;
  word-wrap: break-word;
  background-color: hsl(205, 100%, 98%);
  white-space: normal;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #cccccc;
  scrollbar-color: rgb(242, 242, 242) rgb(235, 235, 235);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-height: calc(100dvh - 448px);
}
.summary-bubble.opn_win{
  margin: 0;
}
.users-list-bubble {
  margin: 1px;
  border-radius: 10px;
  word-wrap: break-word;
  background-color: hsla(0, 0%, 100%, 0.402);
  white-space: normal;
  padding:10px;
  height: calc(100% - 50px);
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #cccccc;
}

@media (max-width: 1460px) {
  .box-left,
  .box-right {
    display: none;
  }

  .chat-list-container {
    max-width: 100%;
  }
}

.users-list-bubble .person{
  scale: 1.4;
  vertical-align: text-bottom;
  display: inline-block;
  margin-right: 10px;
}

.users-list-bubble .participant-list-text{
  display: inline-block;
  width: calc(100% - 60px);
  word-wrap: normal;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
  vertical-align: bottom;
}

.users-list-bubble .participant-list-input {
  width: calc(100% - 60px);
  margin-right: -1px;
}
.users-list-bubble .participant-list-btn {
  margin: 0;
  padding: 0;
  line-height: 1.8em;
  width: 34px;
  vertical-align: bottom;
}
.users-list-bubble .participant-list-btn.stick {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-troubleshooting{
  color: #747474;
  background-color: #faf9f9;
  border-color: #808080;
}

.box-summary .window-stack{
  scale: 1.6;
  display: inline-block;
  margin-left: 10px;
  vertical-align: inherit;
  cursor: pointer;
}

.box-summary .window-stack:hover{
  color: #db4377;
}

.summary-agenda .rbt {
  display: inline-block;
  width: calc(100% - 114px);
  float: left;
}

.summary-agenda .rbt .form-control {
  padding-right: 34px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.summary-agenda .toggle-button {
  background: transparent;
  border: 0;
  bottom: 0;
  padding: 0 12px;
  position: absolute;
  right: 0;
  top: 0;
}
.btn-group > .btn-group:first-child > .btn, .btn-group > .btn:first-child:not(.dropdown-toggle) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group > .btn-group:last-child > .btn, .btn-group > .btn:last-child:not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
}
.btn-group-agenda{
  float: right;
  padding-top: 4px;
  padding-right: 1px;
  width: 114px;
}
.btn-group-agenda button {
  width: 33%;
}

.summary-agenda svg {
  scale: 1.2;
}

.summary-agenda #summary-agenda-add-button-wide {
  padding-right: 30px;
}

.summary-agenda #summary-agenda-add-button-wide svg {
  vertical-align: middle;
  margin-top: -3px;
}

.summary-agenda .control-button {
  font-size: 10px;
  height: 37px;
  vertical-align: baseline;
  line-height: 30px;
  padding: 0 10px;
  margin: -3px 0 0 0;
}
.summary-agenda .list-group-item.active {
  z-index: 2;
  color: #212529;
  background-color: #dcceff;
  border-color: #a5a5a5;
}

.summary-agenda .list-group-item .floating {
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.summary-agenda .list-group-item:hover .floating {
  opacity: 1;
}

.summary-agenda .floating {
  position: absolute;
  top: 4px;
  right: 4px;
}

.summary-agenda .control-button:disabled {
  background: #7F7F7F;
  border-color: #7F7F7F;
}

.summary-bubble ul {
  white-space: normal;
}
.chat-text-container ul, .chat-text-container ol {
  white-space: normal;
}

.markdown-body {
  color: #000 !important;
  background: transparent !important;
  font-size: 88% !important;
}
.chat-row.mine .chat-text-container {
  font-size: 88% !important;
}

.summary-bubble .markdown-container{
  color: #333;
}

.summary-bubble .markdown{
  height: calc(100vh - 452px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 25px 0 15px;
}

.summary-bubble.opn_win .markdown{
  height: calc(100vh - 136px);
}

.markdown pre,
.markdown code{
  font:12px Menlo, Monaco, "DejaVu Sans Mono", "Bitstream Vera Sans Mono",monospace;
}
.markdown pre {
  margin:1em 0;
  font-size:12px;
  background-color:#eee;
  border:1px solid #ddd;
  padding:5px;
  line-height:1.5em;
  color:#444;
  overflow:auto;
  -webkit-box-shadow:rgba(0,0,0,0.07) 0 1px 2px inset;
  -webkit-border-radius:3px;
  -moz-border-radius:3px;border-radius:3px;
}
.markdown pre code {
  font-size: 16px;
  background-color: #f8f8ff;
  color: #444;
  padding: 0 .2em;
  border: 1px solid #dedede;
}
.markdown code {
  font-size: 16px;
  background-color: #f8f8ff;
  color: #444;
  padding: 0 .2em;
  border: 1px solid #dedede;
}
.markdown img{border:0;max-width:100%;}
.markdown abbr{border-bottom:none;}
.markdown a{color:#4183c4;text-decoration:none;}
.markdown a:hover{text-decoration:underline;}
.markdown a code,
.markdown a:link code,
.markdown a:visited code{
  color:#4183c4;
}
.markdown h2,.markdown h3{
  margin:1em 0;
}
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6{
  border:0;
  margin: 0.25em 0;
}
.markdown h1{
  font-size:130%;
}
.markdown h2{
  font-size:120%;
}
.markdown h3{
  font-size:110%;
}
.markdown hr{
  border:1px solid #ddd;
}
.markdown ul{
  margin:0 0 0 0;
  padding-left: 1.5rem;
}
.markdown ol{
  margin:0 0 0 0;
  padding-left: 1.5rem;
}
.markdown ul li,
.markdown ol li{
  margin: 0.5em 0;
}
.markdown ul ul,
.markdown ul ol,
.markdown ol ol,
.markdown ol ul{
  margin-top:0;
  margin-bottom:0;
}
.markdown blockquote{
  margin:1em 0;
  border-left:5px solid #ddd;
  padding-left:.6em;color:#555;
}
.markdown dt{
  font-weight:bold;
  margin-left:1em;
}
.markdown dd{
  margin-left:2em;
  margin-bottom:1em;
}
.markdown sup {
  font-size: 0.83em;
  vertical-align: super;
  line-height: 0;
}
.markdown * {
  -webkit-print-color-adjust: exact;
  white-space: normal;
}

.summary-bubble .cross-fade-container div > *:last-child::after {
  display: block;
  content: '';
  padding-bottom: 6px;
}

@keyframes to-color-change {
  0% { color: #7F7F7F; }
  50% { color: #db4377; }
  100% { color: #333; }
}

@keyframes from-color-change {
  0% { color: #7F7F7F; }
  50% { color: #686898; }
  100% { color: #7F7F7F; }
}

.btn-room-close {
  position: absolute;
  bottom: 31px;
  right: 30px;
}

@media (max-width: 660px) {
  .chat-header {
    max-width: 500px;
  }
  .main-wrapper {
    height: calc(100dvh - 273px);
  }
  .chat-container, .advisor-container {
    height: calc(100dvh - 390px);
    overflow: auto;
  }
}

@media (max-width: 500px) {
  .chat-header {
    max-width: 320px;
  }
  .main-wrapper {
    height: calc(100dvh - 230px);
  }
  .chat-container, .advisor-container {
    height: calc(100dvh - 340px);
    overflow: auto;
  }
}
